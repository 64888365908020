import React, { useState, useEffect } from 'react'
import { CircularProgress, TableRow, TableCell, TableBody } from '@mui/material'
import '../CheckShift/checkShift.css'
import { desktopMonthlyBaseCellStyle } from '../CheckShift/Calendars/calendarStyles'
import MonthlyShiftLeftmostBlock from './MonthlyShiftLeftmostBlock'
import { computeBackgroundColor } from './MonthlyShiftHeader'

const ActualMonthlyShiftBody = ({ employments, monthlyShift, dates }) => {
    const [latestActualWorkDay, setLatestActualWorkDay] = useState(0)
    const fixedCells = ['workday', 'holiday', 'totalHours', 'totalPay']
    useEffect(() => {
        if (monthlyShift.actualWorkHours) {
            let latestWorkDay = 0
            Object.entries(monthlyShift.actualWorkHours).map(awh => {
                awh[1].map(actual => {
                    const currDate = new Date(actual['date']).getDate()
                    if (actual['hours'] > 0) {
                        if (currDate > latestWorkDay) {
                            latestWorkDay = currDate
                        }
                    }
                })
            })
            setLatestActualWorkDay(latestWorkDay)
        }
    }, [monthlyShift.actualWorkHours])
    if (monthlyShift && Object.entries(monthlyShift).length > 0) {
        return (
            <TableBody>
                {employments.map((employment, idx) => {
                    // employees list
                    return (
                        <TableRow key={employment.id}>
                            <MonthlyShiftLeftmostBlock
                                employment={employment}
                                dates={dates}
                                monthlyShift={monthlyShift}
                                latestActualWorkDay={latestActualWorkDay}
                                activeTab="actual"
                            />
                            {dates.map(date => {
                                // daily shift time range
                                let timeToReturn = null
                                const isActual = monthlyShift.datesWithActualWorkHours.includes(date)
                                Object.entries(monthlyShift.actualWorkHours).map(awh => {
                                    if (awh[0] === String(employment.id)) {
                                        awh[1].map(actual => {
                                            if (actual['date'] === date) {
                                                timeToReturn = String(actual['hours'])
                                            }
                                        })
                                    }
                                })
                                if (!isActual) {
                                    Object.entries(monthlyShift.shifts).map(shifts => {
                                        if (shifts[0] === String(employment.id)) {
                                            Object.entries(shifts[1]).map(shift => {
                                                if (shift[0] === date) {
                                                    timeToReturn = shift[1]['shift_hours']
                                                }
                                            })
                                        }
                                    })
                                }
                                const shiftTime = timeToReturn ? `${timeToReturn}h` : '-'

                                if (fixedCells.includes(date)) return null
                                return (
                                    <TableCell
                                        key={date}
                                        sx={{
                                            ...desktopMonthlyBaseCellStyle,
                                            borderRight: '1px solid lightgray',
                                            borderBottom: '1px solid lightgray',
                                            backgroundColor: isActual
                                                ? '#E6F4FF'
                                                : computeBackgroundColor(date, monthlyShift, true)
                                        }}
                                        id={idx}
                                    >
                                        {shiftTime}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    )
                })}
            </TableBody>
        )
    } else {
        return <CircularProgress />
    }
}

export default ActualMonthlyShiftBody
