import React, { useContext } from 'react'
import { List, ListItem, ListItemText, Button, Badge } from '@mui/material'
import { isMobile } from '../../Utilities/isMobile'
import { KeyboardArrowRight } from '@mui/icons-material'
import { PATHS } from '../../constants'
import moment from 'moment'
moment.locale('ja')
import { useHistory } from 'react-router-dom'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'

const ShiftSubmissions = () => {
    const { userEmployments } = useContext(EmploymentsContext)
    const history = useHistory()
    const now = new Date()
    const resentMonths = [new Date(now.getFullYear(), now.getMonth()), new Date(now.getFullYear(), now.getMonth() + 1), new Date(now.getFullYear(), now.getMonth() + 2)]
    const handleClick = (empId, month) => {
        if (month !== JSON.parse(localStorage.getItem('frederickSelectedYearMonth'))) {
            localStorage.setItem('frederickSelectedYearMonth', JSON.stringify(month))
        }
        history.push(PATHS.shiftManagement.replace(':employmentId', empId).replace(':calendarYearMonth', month).replace(':tabValue', 'submit'))
    }
    return <>
    {userEmployments?.map(emp => (
            <List key={emp?.id} sx={{ mx: '1rem'}}>
                {resentMonths.map(e => {
                    const formattedDate = moment(e).format('YYYY-MM')
                    const year = e.getFullYear()
                    const month = e.getMonth() + 1 // js months are 0 index
                    const targetString = moment(e).format('YYYY-MM')
                    const status = emp?.store?.closed_months?.includes(targetString) ? '締め切り済み' : emp.submitted?.find(
                        e => e == `${year}-${String(month).length > 1 ? month : '0' + month}`
                    ) ? '提出済み' : '未提出'
                    const badge = status === '締め切り済み' ? (
                        <Badge
                            badgeContent="締め切り済み"
                            color="paloBlue"
                            sx={{
                                width: '6rem',
                                ml: '-3rem'
                                
                            }}
                        />
                    ) : status === '提出済み' ? (
                        <Badge
                            badgeContent="提出済み"
                            color="success"
                            sx={{
                                width: '4rem',
                                ml: '-1.5rem'
                            }}
                        />
                    ) : (
                        <Badge
                            badgeContent="未提出"
                            color="error"
                            sx={{
                                width: '4rem',
                                ml: '-2rem'
                            }}
                        />
                    )
                    return (
                        <ListItem key={e} >
                            <ListItemText
                                key={month}
                                primary={emp.store.name}
                                secondary={<>{`${month}月分`}{badge}</>}
                            />
                            {isMobile() ? <KeyboardArrowRight sx={{ ml: 'auto' }} onClick={() => handleClick(emp.id, formattedDate)}/> :
                                <Button
                                    sx={{ ml: 'auto' }} 
                                    variant='outlined' 
                                    disabled={status === '締め切り済み' || status === '提出済み'}
                                    onClick={() => handleClick(emp.id, formattedDate)}
                                >
                                    希望シフトを提出する
                                </Button>
                            }
                        </ListItem>
                    )
                })}
            </List>
        ))}
    </>
}

export default ShiftSubmissions