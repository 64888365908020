import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    Typography,
    TextField,
    Stack,
    Box,
    CircularProgress,
    Button,
    Switch,
    Alert,
    Snackbar,
    Divider,
    MenuItem,
    FormControlLabel,
    Checkbox
} from '@mui/material'
import { KeyboardArrowRight, VpnKeyOutlined, DeleteOutlined, MailOutline } from '@mui/icons-material'
import PageWithTitleLayout from '../Layout/PageWithTitleLayout'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import { ROLE_MAPPINGS, PART_FULLTIME_MAPPINGS, OPEN_CLOSE_MAPPINGS } from '../../constants'
import ButtonWithConfirmPrompt from '../ButtonWithConfirmPrompt'
import EditCode from './EditFlows/EditCode'
import EditHallKitchen from './EditFlows/EditHallKitchen'
import EditPartFulltime from './EditFlows/EditPartFulltime'
import EditOpenClose from './EditFlows/EditOpenClose'
import EditStores from './EditFlows/EditStores'
import EditEmail from '../UserSettings/UserEditFlows/EditEmail'
import EditPhone from './EditFlows/EditPhone'
import { PATHS } from '../../constants'
import EditRank from './EditFlows/EditRank'
import EditKanjiName from '../UserSettings/UserEditFlows/EditKanjiName'
import EditKanaName from '../UserSettings/UserEditFlows/EditKanaName'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import SubmitSvg from '../SubmitShift/SubmitSvg'
import EditNickname from '../UserSettings/UserEditFlows/EditNickname'
import EditHourlyWage from './EditFlows/EditHourlyWage'

const currentUser = JSON.parse(localStorage.getItem('currentUser'))

const UserManagementEdit = ({ history }) => {
    const { fetchAndSetEmployments, isAdmin } = useContext(EmploymentsContext)
    const { userEmpId, userId, employmentId } = useParams()
    const [info, setInfo] = useState()
    const [isTargetUserManager, setIsTargetUserManager] = useState()
    const [employments, setEmployments] = useState()
    const [editing, setEditing] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState('')

    const [newPass, setNewPass] = useState(null)

    const fetch = async () => {
        const userInfo = await AxiosWithAuth.get('/users/' + userId)
        const employmentInfo = userEmpId !== 'noEmployment' ? await AxiosWithAuth.get('/employments/' + userEmpId) : {}
        const userEmploymentsInfo = await AxiosWithAuth.get('/employments/?user_id=' + userId)
        setEmployments(userEmploymentsInfo.data?.sort((a, b) => a.id - b.id))
        const infoToAdd = userEmpId === 'noEmployment' ? userEmploymentsInfo?.data[0] : employmentInfo.data
        setInfo({ ...infoToAdd, ...userInfo.data })
        setIsTargetUserManager(userEmploymentsInfo.data.some(e => e.is_manager))
    }

    useMemo(() => {
        if (!history.location.pathname.includes('editing')) {
            setEditing(false)
        }
    }, [history.location.pathname])

    const handleEditing = field => {
        if (!history.location.pathname.includes('editing')) {
            history.push(history.location.pathname + '/editing')
        }
        setEditing(field)
    }

    const handleReturn = () => {
        fetch()
        fetchAndSetEmployments()
        setEditing(false)
    }

    useEffect(() => {
        fetch()
    }, [])

    if (!info) {
        return <CircularProgress sx={{ mx: '50%', mt: '10%' }} />
    }

    if (editing) {
        switch (editing) {
            case 'stores':
                return (
                    <EditStores
                        current={employments}
                        handleReturn={handleReturn}
                        employmentInfo={info}
                        fetchAndSetEmployments={fetchAndSetEmployments}
                        history={history}
                    />
                )
            case 'kananame':
                return (
                    <EditKanaName
                        handleReturn={handleReturn}
                        current={{
                            nameLast: info.name_last,
                            nameFirst: info.name_first
                        }}
                    />
                )
            case 'kanjiname':
                return (
                    <EditKanjiName
                        handleReturn={handleReturn}
                        current={{
                            kanjiLast: info.kanji_last,
                            kanjiFirst: info.kanji_first
                        }}
                    />
                )
            case 'nickname':
                return <EditNickname current={{ nickname: info.nickname }} handleReturn={handleReturn} />
            case 'emp_cd':
                return <EditCode current={info.emp_cd} handleReturn={handleReturn} />
            case 'email':
                return <EditEmail current={info.email} handleReturn={handleReturn} history={history} />
            case 'phone':
                return <EditPhone current={info.phone} handleReturn={handleReturn} />
            case 'kitchen/hall':
                return <EditHallKitchen current={info.role} handleReturn={handleReturn} />
            case 'part/fulltime':
                return <EditPartFulltime current={info.e_type} handleReturn={handleReturn} />
            case 'open/close':
                return <EditOpenClose current={info.open_close_duty} handleReturn={handleReturn} />
            case 'rank':
                return <EditRank current={info.rank} handleReturn={handleReturn} />
            case 'hourly_wage':
                return <EditHourlyWage current={info.hourly_wage} handleReturn={handleReturn} />
        }
    }

    if (newPass)
        return (
            <div
                style={{
                    height: 'calc(100vh - 56px)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    margin: '0 1rem'
                }}
            >
                <SubmitSvg />
                <Typography variant="header1" sx={{ fontSize: '20px' }}>
                    新しいパスワード: {newPass}
                </Typography>
                <Button
                    onClick={() => {
                        setNewPass(null)
                    }}
                    variant="contained"
                    color="paloBlue"
                    fullWidth
                    sx={{ mt: 'auto', mb: '2rem' }}
                >
                    完了
                </Button>
            </div>
        )

    return (
        <PageWithTitleLayout title="ユーザー情報" disableStoreSelect>
            <Box className="input-box shrinkable">
                <Snackbar
                    open={snackbarMessage !== ''}
                    autoHideDuration={4000}
                    onClose={() => setSnackbarMessage('')}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={() => setSnackbarMessage('')}>{snackbarMessage}</Alert>
                </Snackbar>
                {!info.confirmed_at && <Alert severity="error">メールアドレスの本人確認が未完了です。</Alert>}
                <Stack spacing={2} margin="1rem">
                    <TextField
                        id="full name"
                        label="氏名(かな)"
                        onClick={() => handleEditing('kananame')}
                        InputProps={{
                            readOnly: true,
                            endAdornment: <KeyboardArrowRight htmlColor="grey" />
                        }}
                        variant="standard"
                        value={`${info.name_last} ${info.name_first}` || '選択してください'}
                    />
                    <TextField
                        id="kanji name"
                        label="氏名(漢字)"
                        onClick={() => handleEditing('kanjiname')}
                        InputProps={{
                            readOnly: true,
                            endAdornment: <KeyboardArrowRight htmlColor="grey" />
                        }}
                        variant="standard"
                        value={
                            info.kanji_last + info.kanji_first
                                ? `${info.kanji_last} ${info.kanji_first}`
                                : '選択してください'
                        }
                    />
                    <TextField
                        id="nickname"
                        label="ニックネーム"
                        onClick={() => handleEditing('nickname')}
                        InputProps={{
                            readOnly: true,
                            endAdornment: <KeyboardArrowRight htmlColor="grey" />
                        }}
                        variant="standard"
                        value={info.nickname || '入力されていません'}
                    />
                    <TextField
                        id="email"
                        type="email"
                        label="メールアドレス"
                        onClick={() => handleEditing('email')}
                        InputProps={{
                            readOnly: true,
                            endAdornment: <KeyboardArrowRight htmlColor="grey" />
                        }}
                        variant="standard"
                        value={info.email || '選択してください'}
                    />
                    <TextField
                        id="phone"
                        label="電話番号"
                        onClick={() => handleEditing('phone')}
                        InputProps={{
                            readOnly: true,
                            endAdornment: <KeyboardArrowRight htmlColor="grey" />
                        }}
                        variant="standard"
                        value={info.phone || '選択してください'}
                    />
                    <TextField
                        id="stores"
                        label="店舗情報"
                        onClick={() => handleEditing('stores')}
                        InputProps={{
                            readOnly: true,
                            endAdornment: <KeyboardArrowRight htmlColor="grey" />
                        }}
                        variant="standard"
                        value={employments.map(e => ` ${e.store.name}`) || '選択してください'}
                    />
                    <TextField
                        id="emp_code"
                        label="社員コード"
                        onClick={() => isAdmin === true && handleEditing('emp_cd')}
                        InputProps={{
                            readOnly: true,
                            sx: { marginBottom: '.5rem' },
                            endAdornment: isAdmin === true && <KeyboardArrowRight htmlColor="grey" />
                        }}
                        variant="standard"
                        value={info.emp_cd || '選択してください'}
                    />
                    <TextField
                        id="part/fulltime"
                        label="雇用形態"
                        variant="standard"
                        onClick={() => (isTargetUserManager || isAdmin) && handleEditing('part/fulltime')}
                        value={PART_FULLTIME_MAPPINGS[info?.e_type] || '選択してください'}
                        InputProps={{
                            readOnly: true,
                            sx: { marginBottom: '.5rem' },
                            endAdornment: (userId !== String(currentUser?.user_id) || isAdmin) &&
                                !isTargetUserManager && <KeyboardArrowRight htmlColor="grey" />
                        }}
                    />
                    <TextField
                        id="hourly_wage"
                        label="時給"
                        onClick={() => (isTargetUserManager || isAdmin) && handleEditing('hourly_wage')}
                        InputProps={{
                            readOnly: true,
                            sx: { marginBottom: '.5rem' },
                            endAdornment: (isTargetUserManager || isAdmin) && <KeyboardArrowRight htmlColor="grey" />
                        }}
                        variant="standard"
                        value={info.hourly_wage || '選択してください'}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                disabled={info.e_type != 'full-time'}
                                style={{ marginLeft: '-0.75rem' }}
                                checked={info.view_only}
                                onChange={e => {
                                    AxiosWithAuth.put('/users/' + info.id, {
                                        view_only: e.target.checked
                                    }).then(res => {
                                        res.status == 200 && location.reload()
                                    })
                                }}
                            />
                        }
                        label="正社員(間接)"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                style={{ marginLeft: '-0.75rem' }}
                                checked={info.is_student}
                                onChange={e => {
                                    AxiosWithAuth.put('/users/' + info.id, {
                                        is_student: e.target.checked
                                    }).then(res => {
                                        res.status == 200 && location.reload()
                                    })
                                }}
                            />
                        }
                        label="高校生"
                    />
                    {employments.length &&
                        employments.map(e => {
                            return (
                                <div key={e.id}>
                                    <Typography variant="header1" sx={{ fontSize: '20px' }}>
                                        {e.store.name}
                                    </Typography>
                                    <Divider sx={{ marginTop: '.5rem' }} />
                                    <Stack spacing={2} margin="1rem">
                                        <TextField
                                            select
                                            id="kitchen/hall"
                                            label="勤務タイプ"
                                            variant="standard"
                                            placeholder="選択してください"
                                            value={e?.role}
                                            onChange={event =>
                                                AxiosWithAuth.put(`/employments/${e.id}`, {
                                                    role: event.target.value
                                                }).then(() => {
                                                    setSnackbarMessage('勤務タイプを変更しました。')
                                                    fetch()
                                                })
                                            }
                                        >
                                            {Object.keys(ROLE_MAPPINGS).map(key => (
                                                <MenuItem key={key} value={key}>
                                                    {ROLE_MAPPINGS[key]}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <TextField
                                            select
                                            id="open/close"
                                            label="開閉担当者"
                                            variant="standard"
                                            placeholder="選択してください"
                                            value={e.open_close_duty}
                                            onChange={event =>
                                                AxiosWithAuth.put('/employments/' + e.id, {
                                                    open_close_duty: event.target.value
                                                }).then(() => {
                                                    fetch()
                                                    setSnackbarMessage('開閉担当者を変更しました。')
                                                })
                                            }
                                        >
                                            {Object.keys(OPEN_CLOSE_MAPPINGS).map(key => (
                                                <MenuItem key={key} value={key}>
                                                    {' '}
                                                    {OPEN_CLOSE_MAPPINGS[key]}{' '}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <TextField
                                            select
                                            id="rank"
                                            label="ランク"
                                            variant="standard"
                                            placeholder="選択してください"
                                            value={e.rank}
                                            onChange={event =>
                                                AxiosWithAuth.put('/employments/' + e.id, {
                                                    rank: event.target.value
                                                }).then(() => {
                                                    fetch()
                                                    setSnackbarMessage('ランクを変更しました。')
                                                })
                                            }
                                        >
                                            {['A', 'B', 'T'].map(key => (
                                                <MenuItem key={key} value={key}>
                                                    {key}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Stack>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            margin: '0 1rem'
                                        }}
                                    >
                                        <Typography variant="subtitle1">シフト更新権限</Typography>
                                        <Switch
                                            color="paloBlue"
                                            checked={e.can_edit_shift}
                                            onChange={event => {
                                                AxiosWithAuth.put('/employments/' + e.id, {
                                                    can_edit_shift: event.target.checked
                                                }).then(res => {
                                                    res.status == 200 &&
                                                        res.data.can_edit_shift &&
                                                        setSnackbarMessage('シフトの更新権限を付与しました。')
                                                    fetch()
                                                })
                                            }}
                                        />
                                    </div>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            margin: '0 1rem',
                                            marginBottom: '1rem',
                                            marginRight: '2rem',
                                            color: 'grey'
                                        }}
                                    >
                                        シフト更新権限があるユーザーはシフトを作成することができます。
                                    </Typography>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            margin: '0 1rem'
                                        }}
                                    >
                                        <Typography variant="subtitle1">マネージャー</Typography>
                                        <Switch
                                            color="paloBlue"
                                            checked={e.is_manager}
                                            disabled={info.e_type != 'full-time'}
                                            onChange={event => {
                                                AxiosWithAuth.put('/employments/' + e.id, {
                                                    is_manager: event.target.checked
                                                }).then(res => {
                                                    res.status == 200 &&
                                                        res.data.is_manager &&
                                                        setSnackbarMessage('マネージャー権限を付与しました。')
                                                    fetch()
                                                })
                                            }}
                                        />
                                    </div>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            margin: '0 1rem',
                                            marginBottom: '1rem',
                                            marginRight: '2rem',
                                            color: 'grey'
                                        }}
                                    >
                                        正社員のみマネージャーにできます。
                                    </Typography>
                                </div>
                            )
                        })}
                </Stack>
                {!info.confirmed_at && (
                    <ButtonWithConfirmPrompt
                        component={
                            <Button variant="outlined" color="paloBlue" sx={{ mx: '1rem', mb: '1rem' }}>
                                <MailOutline sx={{ mr: '.5rem' }} />
                                メールアドレスを代理で確認
                            </Button>
                        }
                        title="メールアドレスを代理で確認"
                        content="このユーザーのメールアドレスを代理で確認しますか？"
                        confirmText="確認する"
                        handleConfirm={() => {
                            AxiosWithAuth.post('/confirmUser/', { id: info.id }).then(res => {
                                console.log(res.data)
                                if (res.status == 200) {
                                    setSnackbarMessage('メールアドレスの本人確認が完了しました。')
                                    fetch()
                                }
                            })
                        }}
                    />
                )}
                <ButtonWithConfirmPrompt
                    component={
                        <Button variant="outlined" color="paloBlue" sx={{ mx: '1rem', mb: '1rem' }}>
                            <VpnKeyOutlined sx={{ mr: '.5rem' }} />
                            このユーザーのパスワードをリセット
                        </Button>
                    }
                    title="パスワードのリセット"
                    content="このユーザーのパスワードをリセットしますか？"
                    confirmText="リセットする"
                    handleConfirm={() => {
                        AxiosWithAuth.post('/resetPassword', { id: info.id }).then(res => {
                            if (res.status == 200) {
                                setNewPass(res.data.password)
                            }
                        })
                    }}
                />
                <ButtonWithConfirmPrompt
                    component={
                        <Button variant="outlined" color="error" sx={{ mx: '1rem', mb: '2rem' }}>
                            <DeleteOutlined sx={{ mr: '.5rem' }} />
                            このユーザーのアカウントを無効にする
                        </Button>
                    }
                    title="ユーザーの無効化"
                    content="アカウントを無効にすると、このユーザーはログインできなくなります。このユーザーを無効にしてもよろしいですか？"
                    confirmText="無効にする"
                    handleConfirm={() => {
                        AxiosWithAuth.post('/users/deactivate/' + userId).then(res => {
                            res.status == 200 && res.data.message === "User deactivated" && (window.location.pathname = 'managerSettings/userManagement/' + employmentId)
                        })
                    }}
                />
                
                {isAdmin && (
                    <ButtonWithConfirmPrompt
                        component={
                            <Button variant="outlined" color="error" sx={{ mx: '1rem', mb: '5rem' }}>
                                <DeleteOutlined sx={{ mr: '.5rem' }} />
                                このユーザーのアカウントを削除
                            </Button>
                        }
                        title="ユーザーの削除"
                        content="アカウントを削除すると過去のデータはすべて削除され、復元することはできません。このユーザーのアカウントを完全に削除してもよろしいですか？"
                        confirmText="削除する"
                        handleConfirm={() => {
                            AxiosWithAuth.delete('/users/' + userId).then(res => {
                                res.status == 204 &&
                                    history.push({ pathname: PATHS.adminUserManagements, state: { deleted: userId } })
                            })
                        }}
                    />
                )}
            </Box>
        </PageWithTitleLayout>
    )
}

export default UserManagementEdit
