import React, { useContext, useEffect, useMemo, useState } from 'react'
import { EmploymentsContext } from '../../contexts/EmploymentsContext'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import { useParams } from 'react-router-dom'
import { Input, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import './HLGAdjustments.css'
import { debounce } from 'lodash'

export default function HLGAdjustments() {
    const { userEmployments } = useContext(EmploymentsContext)
    const { employmentId, calendarYearMonth } = useParams()
    
    const [hlgAdjustments, setHlgAdjustments] = useState()
    const [salesActuals, setSalesActuals] = useState()
    const [startAndEndHours, setStartAndEndHours] = useState([0, 24])
    const lastMonthStart = moment(calendarYearMonth).subtract(1, 'month').startOf('month')
    const lastMonthEnd = moment(calendarYearMonth).subtract(1, 'month').endOf('month')
    const [selectedSalesRange, setSelectedSalesRange] = useState([lastMonthStart, lastMonthEnd])
    const [revenueStaffingRows, setRevenueStaffingRows] = useState()

    const employment = useMemo(
        () => userEmployments.find(employment => employment.id === parseInt(employmentId)),
        [employmentId, userEmployments]
    )

    const fetchHlgAdjustments = () => {
        if (!employment) return
        if (!calendarYearMonth) return
        AxiosWithAuth.get(`/stores/${employment.store.seg_code}/hlg_adjustments?year_month=${moment(calendarYearMonth).format('YYYY-MM')}`).then(
            res => setHlgAdjustments(res.data)
        )
    }

    useEffect(() => {
        fetchHlgAdjustments()
    }, [calendarYearMonth, employment])

    useEffect(() => {
        if (!employment) return
        AxiosWithAuth.get(
            `/stores/${employment.store.seg_code}/sales_actuals?start_date=${selectedSalesRange[0].format(
                'YYYY-MM-DD'
            )}&end_date=${selectedSalesRange[1].format('YYYY-MM-DD')}`
        ).then(res => {
            const nonEmptyCategories = Object.entries(res.data)
                .filter(([, hours]) => Object.keys(hours).length > 0)
                .map(([, hours]) => 
                    Object.entries(hours).filter(([, value]) => parseFloat(value) !== 0)
                )
                .filter(hours => hours.length > 0)

            if (nonEmptyCategories.length === 0) {
                setStartAndEndHours([0, 24]) // Default fallback
                setSalesActuals(res.data)
                return
            }
            
            const earliestHour = Math.min(...nonEmptyCategories.map(hours => hours[0][0])) - 1
            const latestHour = Math.max(...nonEmptyCategories.map(hours => hours[hours.length - 1][0])) + 1
        
            setStartAndEndHours([earliestHour, latestHour])
            setSalesActuals(res.data)
        })
    }, [selectedSalesRange, employment])

    useEffect(() => {
        if (!employment) return

        AxiosWithAuth.get(`/revenueStaffings/store/${employment.store.seg_code}`).then(res => {
            const staffings = res.data

            const daytime = staffings.override_staffings.filter(staffing => staffing.shift === 'Daytime')
            const night = staffings.override_staffings.filter(staffing => staffing.shift === 'Night')
            setRevenueStaffingRows({ Daytime: daytime, Night: night })
        })
    }, [employment])

    const updateHlgAdjustment = (dow_category, hour, adjustment = null, open_close_adjustment = null) => {
        AxiosWithAuth.post(`/hlg_adjustments`, {
            store_id: employment.store.id,
            year_month: moment(calendarYearMonth).format('YYYY-MM'),
            dow_category,
            hour,
            adjustment,
            open_close_adjustment
        }).then(() => fetchHlgAdjustments())
    }

    const debouncedUpdateHlgAdjustment = debounce(updateHlgAdjustment, 500)
    
    // table structure:
    // columns will be 1 for each hour of the day
    // rows will be grouped into 4 sections, mon-thurs, fri, sat, sun_holidays
    // rows will be sales average for that hour, staff required according to hlg for that hour, manager adjustment (adding or removing number of staff), opening closing adjustment, and then final staff required
    // final staff required will be staff required according to hlg + manager adjustment + opening closing adjustment

    const dow_categories = ['mon_thurs', 'fri', 'sat', 'sun_holidays']
    const dow_categories_localized = {
        mon_thurs: '月-木',
        fri: '金',
        sat: '土曜',
        sun_holidays: '日祝'
    }
    const hours = Array.from(
        { length: startAndEndHours[1] - startAndEndHours[0] + 1 },
        (_, i) => i + startAndEndHours[0]
    )

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'center', margin: '0 1rem' }}>
                    <Typography variant="h6">
                        {calendarYearMonth && `${calendarYearMonth.slice(0, 4)}年${calendarYearMonth.slice(5, 7)}月`}
                    </Typography>
                    <Typography variant="subtitle1" sx={{ marginLeft: '1rem' }}>
                        必要人数を調整してください。
                    </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', margin: '0 1rem' }}>
                    <DatePicker
                        sx={{ marginRight: '1rem' }}
                        label="開始日"
                        value={selectedSalesRange[0]}
                        onChange={v => setSelectedSalesRange(prev => [v, prev[1]])}
                    />
                    <DatePicker
                        label="終了日"
                        value={selectedSalesRange[1]}
                        onChange={v => setSelectedSalesRange(prev => [prev[0], v])}
                    />
                </div>
            </div>

            {salesActuals && revenueStaffingRows && hlgAdjustments && (
                <table className="hlg-adjust-table">
                    <thead>
                        <tr>
                            <th style={{ width: '3rem' }}>区分</th>
                            <th>区分</th>
                            {hours.map(hour => {
                                return (
                                    <th className="hlga-hour-row" key={hour}>
                                        {hour}
                                    </th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {dow_categories.filter(dw => Object.entries(salesActuals[dw]).length > 0).map(dw => {
                            return (
                                <>
                                    <tr key={dw}>
                                        <td rowSpan={6}>{dow_categories_localized[dw]}</td>
                                        <td>直近平均売上</td>
                                        {hours.map(hour => {
                                            return (
                                                <td className="hlga-hour-row" key={hour}>
                                                    {parseInt(salesActuals[dw][hour] || 0)}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                    <tr>
                                        <td>直近売上モデル時間</td>
                                        {hours.map(hour => {
                                            const shift = hour > employment.store.night_start ? 'Night' : 'Daytime'
                                            const staffing = revenueStaffingRows[shift].find(
                                                staffing =>
                                                    staffing.hourly_rev_high > salesActuals[dw][hour] &&
                                                    staffing.hourly_rev_low <= salesActuals[dw][hour]
                                            )
                                            return (
                                                <td className="hlga-hour-row" key={hour}>
                                                    {staffing?.workers_required}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                    <tr className="yellow-row">
                                        <td>調整時間 [要営業部長承認]</td>
                                        {hours.map(hour => {
                                            const adjustment =
                                                hlgAdjustments.find(
                                                    hlga => hlga.dow_category === dw && hlga.hour === hour
                                                )?.adjustment || 0
                                            return (
                                                <td className="hlga-hour-row" key={hour}>
                                                    <Input
                                                        type="number"
                                                        defaultValue={adjustment}
                                                        onChange={e =>
                                                            debouncedUpdateHlgAdjustment(dw, hour, parseInt(e.target.value) || 0)
                                                        }
                                                        sx={{
                                                            width: '3.5rem',
                                                        }}
                                                        inputProps={{
                                                            style: { textAlign: 'center', marginLeft: "1rem" }
                                                        }}
                                                    />
                                                </td>
                                            )
                                        })}
                                    </tr>
                                    <tr>
                                        <td>モデル時間</td>
                                        {hours.map(hour => {
                                            const shift = hour > employment.store.night_start ? 'Night' : 'Daytime'
                                            const staffing = revenueStaffingRows[shift].find(
                                                staffing =>
                                                    staffing.hourly_rev_high > salesActuals[dw][hour] &&
                                                    staffing.hourly_rev_low <= salesActuals[dw][hour]
                                            )
                                            const adjustment =
                                                hlgAdjustments.find(
                                                    hlga => hlga.dow_category === dw && hlga.hour === hour
                                                )?.adjustment || 0
                                            return (
                                                <td className="hlga-hour-row" key={hour}>
                                                    {staffing?.workers_required + adjustment}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                    <tr className="yellow-row">
                                        <td>開・閉店時間</td>
                                        {hours.map(hour => {
                                            const open_close_adjustment =
                                                hlgAdjustments.find(
                                                    hlga => hlga.dow_category === dw && hlga.hour === hour
                                                )?.open_close_adjustment || 0
                                            return (
                                                <td className="hlga-hour-row" key={hour}>
                                                    <Input
                                                        type="number"
                                                        defaultValue={open_close_adjustment}
                                                        onChange={e =>
                                                            debouncedUpdateHlgAdjustment(
                                                                dw,
                                                                hour,
                                                                null,
                                                                parseInt(e.target.value) || 0
                                                            )
                                                        }
                                                        sx={{
                                                            width: '3.5rem',
                                                        }}
                                                        inputProps={{
                                                            style: { textAlign: 'center', marginLeft: "1rem" }
                                                        }}
                                                    />
                                                </td>
                                            )
                                        })}
                                    </tr>
                                    <tr>
                                        <td>モデル時間 [開・閉店時間含む]</td>
                                        {hours.map(hour => {
                                            const shift = hour > employment.store.night_start ? 'Night' : 'Daytime'
                                            const staffing = revenueStaffingRows[shift].find(
                                                staffing =>
                                                    staffing.hourly_rev_high > salesActuals[dw][hour] &&
                                                    staffing.hourly_rev_low <= salesActuals[dw][hour]
                                            )
                                            const hlga = hlgAdjustments.find(
                                                hlga => hlga.dow_category === dw && hlga.hour === hour
                                            )
                                            const adjustment = hlga?.adjustment || 0
                                            const open_close_adjustment = hlga?.open_close_adjustment || 0
                                            return (
                                                <td className="hlga-hour-row" key={hour}>
                                                    {staffing?.workers_required + adjustment + open_close_adjustment}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                </>
                            )
                        })}
                    </tbody>
                </table>
            )}
        </>
    )
}
